
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































































































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.page-header-image {
  max-width: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 4rem;
  }
}

.page-header {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @include mq(m) {
    flex-direction: row;
    align-items: flex-start;
  }

  &__title {
    h1.main-title {
      display: block;

      @include mq(m) {
        display: inline-block;
      }
    }

    span.subtitle {
      display: inline-block;
      margin-left: 0;
      font-size: 1.4rem;
      @include mq(m) {
        margin-left: 1.5rem;
      }
    }
  }

  &__actions {
    margin-left: auto;
    flex: 0 0 auto;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: stretch;
      margin-top: 30px;

      a {
        width: 100%;
      }
    }
  }
}

.facets {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-bottom: 4rem;
  margin-top: 4rem;

  @include mq(m) {
    flex-flow: row wrap;
  }

  &__search {
    width: 100%;
    margin-bottom: 2rem;
    @include mq(m) {
      max-width: 41.9rem;
      margin-bottom: unset;
    }

    .input-wrapper {
      position: relative;

      &:after {
        content: url('../../../assets/svg/commune/search.svg');
        width: 1.9rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1rem);
        transition: opacity 0.3s ease-in-out;
      }

      &:focus-within:after {
        opacity: 0.5;
      }
    }

    input.search {
      width: 100%;
      border: 0;
      background-color: $c-gray-lightest;
      border-radius: 0.5rem;
      height: 5.5rem;
      padding: 1rem 2rem;

      &:focus {
        border: 1px solid $c-gray-darker;
        background-color: $c-white;
      }
    }
  }

  &__filters {
    font-size: 1.4rem;
    display: flex;
    flex-flow: column;
    margin-left: auto;
    width: 100%;

    @include mq(m) {
      display: flex;
      flex-flow: row wrap;
      width: auto;
    }

    &__label {
      display: flex;
      align-items: center;
      color: $c-main-primary;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include mq(m) {
        margin-bottom: unset;
      }

      > svg {
        margin-right: 0.6rem;
        fill: $c-main-primary;
      }
    }
  }

  .select-box {
    @include mq(m) {
      margin-left: 0.5rem;
      margin-bottom: unset;
    }
    flex-flow: row wrap;
    margin-bottom: 1rem;
  }
}

::v-deep div.panel-accordion {
  border-radius: 1rem;
  transition: 0.2s ease-out;
  box-shadow: 0 0 0 #18214d20;
}

::v-deep div.panel-accordion.active {
  border-color: transparent;
  box-shadow: 0 0 24px #18214d20;
}

::v-deep div.panel-accordion div.panel-accordion-title {
  padding: 25px 30px;
  display: flex;
  align-items: center;

  &:hover span {
    font-weight: initial;
  }

  &.active .level-name {
    font-weight: initial;
  }

  span {
    font-size: 0.875em;
    max-width: 40rem;

    @media screen and (max-width: 767px) {
      max-width: 40vw;
    }
  }

  .side-item {
    position: absolute;
    left: 50rem;
    margin-left: unset;

    @media screen and (max-width: 767px) {
      left: unset;
      right: 20px;

      .text {
        display: none;
      }
    }
  }

  .badge-rounded {
    display: inline-block;
    height: 2.5rem;
    min-width: 2.5rem;
    padding: 0 0.4rem;
    margin-right: 5px;
    background-color: $c-green;
    border-radius: 2rem;
    font-weight: normal;
    color: white;
    text-align: center;
    line-height: 2.5rem;
  }

  .panel-accordion-arrow {
    fill: $c-gray-darkest;
    float: none;
    position: absolute;
    right: 10px;
    margin-top: 0;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}

::v-deep .vgt-wrap .vgt-responsive {
  border: 0;
  box-shadow: none;
}

::v-deep .vgt-wrap table.vgt-table tbody td {
  border-top: 0;

  .vgt-actions {
    height: 44px;
    padding-block: 0;
    width: 190px !important;
    margin-left: auto;

    @media screen and (max-width: 767px) {
      height: unset;
      width: auto !important;
    }

    .action__label {
      line-height: 42px;
      display: block;

      @media screen and (max-width: 767px) {
        padding-block: 0.3em;
        line-height: 1.5em;
      }
    }
  }
}

::v-deep .vgt-wrap table.vgt-table thead th {
  border-bottom: 0 !important;
}

::v-deep table.vgt-table {
  margin-bottom: 1rem;

  th,
  td {
    &.compteur,
    &.energie,
    &.date,
    &.releves {
      display: none;
      @include mq(s) {
        display: table-cell;
      }
    }
  }

  td {
    border-color: transparent;
  }

  thead {
    @media screen and (max-width: 767px) {
      display: block;
    }

    th {
      border-color: #dfdfdf !important;
      width: 20rem !important;

      @media screen and (max-width: 767px) {
        border-color: transparent !important;
      }

      &.sortable {
        button {
          &:after,
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

::v-deep .index-table {
  margin: 0 2rem;
}

::v-deep .custom-header__title {
  color: $c-green;
}

::v-deep .custom-header__icon {
  svg {
    fill: $c-green;
  }
}

.search-sort {
  cursor: pointer;
  display: flex;
  margin-left: 40px;

  &__title {
    font-size: 1.4rem;
    color: #8b8b8b;
    font-weight: normal;
  }

  &__icon {
    margin-left: 0.5rem;

    > svg {
      fill: #8b8b8b;
    }
  }

  &__item {
    margin-right: 40rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-right: 28rem;
    }

    &.active {
      .search-sort__title {
        color: $c-orange;
      }

      .search-sort__icon {
        > svg {
          fill: $c-orange;
        }
      }
    }
  }
}
